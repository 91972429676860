[inert] {
  pointer-events: none;
  cursor: default;
}

[inert], [inert] * {
  -webkit-user-select: none;
  user-select: none;
}

/*# sourceMappingURL=form-submission.a15ef959.css.map */
